import Sliders from './honeyBuffChicken.jpg';
import styled from 'styled-components';

const Span = styled.span `
	color: black;
	font-family: Lato;
	font-size: 18px;
	font-weight: bold;
`

const Table = styled.table `
	background-color: rgb(255, 253, 231, 0.8);
	border-radius: 25px;
	margin: auto;
	color: black;
	padding-left: 10px;
`

const Img = styled.img `
	height: auto;
	width: 275px;
	border-radius: 25px;
`

const Ul = styled.ul `
	list-style-type: none;
	font-family: Lato;
`

const Td = styled.td `
	@media (max-width: 425px) {
		display: none;
	}
`

export default function MealOne() {
	return (
    <Table>
			<tbody>
				<tr>
					<Td>
						<Span><u>Honey Chicken Sliders - September 15th, 2021!</u></Span><br/><br/><br/>
						<Img src={Sliders} alt='Honey Chicken Sliders' />
					</Td>
					<td>
						<Ul>
							<li><span style={{fontWeight: 'bold', fontSize: 18}}>Ingredients!</span> <br />2 lbs boneless skinless chicken breast</li>
							<li>4 tbsp butter</li>
							<li>3/4 cup hot sauce of choice</li>
							<li>1/4 cup + 2 tbsp honey</li>
							<li>2 tbsp brown sugar</li>
							<li>2 tbsp soy sauce</li>
							<li>3 tbsp tomato ketchup</li>
							<li>1/2 tsp paprika</li>
							<li>1/2 tsp dried thyme</li>
							<li>1 tsp salt</li>
							<li>Black pepper</li>
							<li>1 1/2 tbsp cornstarch/corn flour</li>
							<li>7 rolls</li>
							<li><br /><span style={{fontWeight: 'bold', fontSize: 14}}><u>Stream details to be sent closer to date.<br />
							Event will last about 1 hour.</u></span></li>
						</Ul>
					</td>
				</tr>
			</tbody>
		</Table>
	);
}
