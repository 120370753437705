import React from 'react';
import './App.css';
import MonthButton from './button.js';
/*import Oauth from './oauthLink.js';

 Potential Title fonts:
font-family: 'Cinzel Decorative, cursive;
font-family: 'Comfortaa, cursive;
font-family: 'Lato, sans-serif;
font-family: 'Open Sans Condensed', sans-serif;
font-family: 'Oswald, sans-serif;
font-family: 'Zen Loop, cursive'
font-family: 'STIX Two Math, serif'
font-family: 'Satisfy, cursive'
font-family: 'Playfair Display SC, serif' -> NO
font-family: 'Old Standard TT, serif' -> NO
font-family: 'Architects Daughter, cursive' -> NO
font-family: 'Amatic SC, cursive'
font-family: 'Open Sans, sans-serif'
font-family: 'Roboto, sans-serif'*/

class App extends React.Component {
  render() {

    return (
      <div className="App">
        <h1 style={{fontSize:84, fontFamily: 'Amatic SC, cursive', marginBottom: 20}}>Love Eats - Streams</h1>
        <section style={{fontFamily: 'Open Sans, sans-serif', borderRadius: 55, width: '70%', margin: 'auto', fontSize: 20, backgroundColor: "rgb(64, 60, 45, 0.75)", padding: 15}}>
          <p>Greetings - Foodies! Welcome to <u>Love Eats - Streaming Edition</u>!</p>
          <p>This is an exciting opportunity to learn how to cook	some of my favorite recipes through an online cook-along! <u>All you need to bring are the ingredients & an appetite</u>!</p>
          <p><u>Check out my first offering in September by clicking the button below!</u>! If satisfied, purchase and reserve your spot in the form below! All online cooking experiences
          are $20 and expected to last at least one (1) hour. Thank you for the continued support & I look forward to cooking with you!</p>
        </section>
        <MonthButton />
      </div>
    );
  }
}

export default App;
