import styled from 'styled-components';
import MealOne from './foodOne.js';
import MealTwo from './foodTwo.js';

const Button = styled.button `
	background-color: white;
	padding: 5px 10px 0px 10px;
	border-radius: 10px;
	outline: 0;
	text-transform: uppercase;
	box-shadow: 0px 2px 2px rgb(64, 60, 45);
	margin: 30px 20px 30px 20px;
	cursor: pointer;
	color: black;
	transition: ease background-color 400ms;
	&:hover {
		background-color: rgb(85, 141, 207);
		color: white;
	}
	font-size: 24px;
	font-family: Comfortaa, cursive;
`

export default function MonthButton() {

	function clickMe() {
		var x = document.getElementById("meals");
		if (x.style.display === "none") {
			x.style.display = "block";
		} else {
			x.style.display = "none";
		}
	}

	function clickMeTwo() {
		var x = document.getElementById("mealtwo");
		if (x.style.display === "none") {
			x.style.display = "block";
		} else {
			x.style.display = "none";
		}
	}

	return (
		<div>
			<div className="MonthButton">
				<Button onClick={clickMe}>September</Button>
				<Button onClick={clickMeTwo}>October</Button>
			</div>

			<div style={{display: 'none', marginBottom: '20px'}} id="meals">
				<MealOne />
			</div>

			<div style={{display: 'none', marginBottom: '20px'}} id="mealtwo">
				<MealTwo />
			</div>

		</div>
	);
}
