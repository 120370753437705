import styled from 'styled-components';
import potRoast from './potRoast.jpg';

	const Caption = styled.caption `
		color: white;
		font-family: Lato;
		font-size: 24px;
		font-weight: bold;
	`

	const Table = styled.table `
		background-color: rgb(255, 253, 231, 0.8);
		border-radius: 25px;
		margin: auto;
		color: black;
		padding-left: 10px;
	`

	const Img = styled.img `
		height: 300px;
		width: auto;
		border-radius: 25px;
	`

	const Ul = styled.ul `
		list-style-type: none;
		font-family: Lato;
	`

	const Td = styled.td `
		@media (max-width: 425px) {
			display: none;
		}
	`

export default function MealTwo() {
	return (
		<Table>
				<Caption>Pot Roast - October 8th, 2021!</Caption>
					<tbody>
						<tr>
							<Td>
								<Img src={potRoast} alt='Potty Roast' />
							</Td>
							<td>
								<Ul>
									<li><span style={{fontWeight: 'bold', fontSize: 18}}>Ingredients!</span> <br />Salt & freshly ground black pepper</li>
									<li>One 3-5 lb chuck roast</li>
									<li>2-3 tbsp olive oil</li>
									<li>2 whole onions, peeled & halved</li>
									<li>6-8 whole carrots, unpeeled, cut into 2-in pieces</li>
									<li>1 cup red wine, optional</li>
									<li>3 cups beef broth</li>
									<li>2-3 sprigs fresh rosemary</li>
									<li>2-3 sprigs fresh thyme</li>
									<li><br /><span style={{fontWeight: 'bold', fontSize: 14}}>Stream details to be sent closer to date.<br />
									Event will last about 1 hour.</span></li>
								</Ul>
							</td>
						</tr>
					</tbody>
		</Table>
	);
}
